<template>
  <div class="forbidden">
    <div class="container-fluid px-1 px-md-5 px-lg-1 px-xl-5 py-5 mx-auto">
      <div class="card card0 border-3" style="border-radius: 5px">
        <div class="row d-flex">
          <div class="col-lg-7">
            <div class="card1 pb-5">
              <div class="row">
                <img src="/logo.png" class="logo" />
              </div>
              <div class="row px-3 justify-content-center mt-4 mb-5 border-line">
                <img src="/nuta.png" class="image" style="padding-top: 100px" />
              </div>
            </div>
          </div>
          <div class="col-lg-5">
            <div class="card2 card border-0 px-4 py-5">
              <div class="row">
                <div class="col-lg-12 text-center">
                  <h6><b>Aplikasi hanya bisa dibuka pada laptop atau komputer</b></h6>
                </div>
              </div>
              <div class="row mb-3" style="padding-top: 30%; padding-left: 65%"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
    };
  },
  methods: {
  },
};
</script>
<style scoped>
body {
  color: #000;
  overflow-x: hidden;
  background-repeat: no-repeat;
}
.card0 {
  box-shadow: 0px 2px 4px 0px #757575;
  border-radius: 0px;
}
.card2 {
  margin: 0px 40px;
}
.logo {
  width: 150px;
  height: 30px;
  transform: translateX(30px) translateY(21px);
}
.image {
  width: 650px;
  height: 250px;
}
.border-line {
  border-right: 1px solid #eeeeee;
}
.line {
  height: 1px;
  width: 45%;
  background-color: #e0e0e0;
  margin-top: 10px;
}
.or {
  width: 10%;
  font-weight: bold;
}
.text-sm {
  font-size: 14px !important;
}
button:focus {
  -moz-box-shadow: none !important;
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
  outline-width: 0;
}
a {
  color: inherit;
  cursor: pointer;
}
.btn-green {
  background-color: #00ae2b;
  width: 150px;
  color: #fff;
  border-radius: 2px;
}
.btn-green:hover {
  background-color: #019b28;
  cursor: pointer;
}
.bg-blue {
  color: #fff;
  background-color: #1a237e;
}
@media screen and (max-width: 991px) {
  .logo {
    margin-left: 0px;
  }

  .image {
    width: 300px;
    height: 220px;
  }

  .border-line {
    border-right: none;
  }

  .card2 {
    border-top: 1px solid #eeeeee !important;
    margin: 0px 15px;
  }
}
</style>